::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d4e9ec;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5bb7de;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7edbe2;
}

table {
  width: 100% !important;
}
