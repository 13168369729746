.react-date-picker__inputGroup {
  display: flex;
  align-items: center;
}

.react-date-picker__calendar {
  display: none !important;
}

#keep-calendar-open .react-date-picker__calendar {
  display: block !important;
}
#keep-calendar-open-modal .react-date-picker__calendar {
  display: block !important;
  position: absolute !important;
  top: -300px!important;
}

#keep-calendar-open .react-date-picker__calendar--closed {
  display: none !important;
}
#keep-calendar-open-modal .react-date-picker__calendar--closed {
  display: none !important;
}
#openseadragon {
  position: relative !important;
  width: 100%;
  height: 100%;
}

#openseadragon-nav {
  position: absolute !important;
  width: 15vw;
  height: 20vh;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.openseadragon-container {
  border: 1px solid #aab5c3 !important;
  border-radius: 16px !important;
}

.referencestrip {
  margin: 0 !important;
  margin-bottom: 0 !important;
}

.referencestrip:hover {
  margin: 0 !important;
}

.referencestrip > div {
  padding: 0 !important;
}

.openseadragon-container {
  border: none !important;
  background: white !important;
}